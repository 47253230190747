$color-highlight-2: #00FFDC;

strong {
  font-weight: 500;
}

.under-construction {
  margin: 100px auto;
  display: block;
}
nav {
  .nav-logotype {
    margin-top: -10px;
  }
}
footer .footer-infos {
  background-color: #ECF8FE;
}

.button-center {
  text-align: center;
  margin: 30px 20px;
}

button {
  border: 0;
  border-radius: 25px;
  height: 50px;
  padding: 15px 35px;
  background-color: $color-secondary;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  @include font-size(15px);
  font-weight: 500;

  &:hover {
    background-color: $color-base;
  }
}

.p-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $color-highlight-2;
  display: inline-block;
  margin: 0 10px 0 0;
}

.wrapper-home {

  .teaser {
    @include font-size(24px);
    letter-spacing: 2px;
    font-style: italic;
    text-align: center;
    margin: 50px 20px;

    @include custom(960) {
      margin-top: 0;
    }
  }
  .player-intro {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .video {
    text-align: center;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;

    position: relative;
    overflow: hidden;

    video {
      width: inherit;
      border-radius: 20px;

      @include custom(1366) {
        border-radius: 0;
      }
      @include custom(600) {
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }
  }

  .twister {
    margin: 70px auto;
    text-align: center;
  }

  .presentation {
    max-width: 950px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;

    @include custom(1100) {
      flex-direction: column;
      align-items: center;
      padding: 0 30px;
    }

    .presentation-tv {
      @include font-size(13px);
      color: $color-secondary;
      text-align: center;
      line-height: 16px;

      p:first-of-type {
        color: $color-base;
        margin-top: 10px;
      }
    }

    .first-p,
    .second-p {
      line-height: 22px;

      p {
        margin-bottom: 18px;
      }
    }

    .first-p {
      max-width: 300px;

      @include custom(1100) {
        max-width: 500px;
      }
    }
    .second-p {
      max-width: 380px;

      @include custom(1100) {
        max-width: 500px;
      }

      .line {
        margin: 30px 30px 0 0;
        background-color: $color-highlight-2;
        width: 150px;
        height: 3px;
        border-radius: 1.5px;
        float: right;
      }
    }
  }

  .products-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 50px 20px;
    flex-wrap: wrap;

    .category {
      cursor: pointer;

      &:hover {
        > div {
          border: solid 1px $color-secondary;
          border-radius: 50px;
        }
      }

      > div {
        width: 330px;
        height: 330px;
        border: solid 1px #ffffff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        @include transition(all ease-in-out 0.2s);

      }
      p {
        margin-top: 15px;
        text-align: center;
      }
    }
  }

  .advantages {
    background-image: url("../public/assets/svg/wave.svg");
    width: auto;
    height: 1250px;
    max-width: 1366px;
    margin: 0 auto;

    @include custom(1024) {
      height: inherit;
      padding: 0 30px 50px;
      background-size: cover;
    }

    .titles {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 200px;
      @include font-size(38px);
      max-width: 900px;
      margin: 0 auto;
      padding: 50px 20px;

      @include custom(1024) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        max-width: 600px;
      }

      > p {
        font-weight: 300;
      }
      div p {
        font-weight: 600;
      }
      .line {
        margin: 30px 30px 0 0;
        background-color: $color-highlight-2;
        width: 150px;
        height: 3px;
        border-radius: 1.5px;
        float: right;
      }
    }

    .infos {
      background-image: url("../public/assets/images/advantages-brush.png");
      background-position: 78% 45%;
      background-repeat: no-repeat;
      width: auto;
      height: 800px;
      padding-top: 100px;

      @include custom(1024) {
        padding-top: 0;
        background-position: top 250px right -50px;
      }
      @include custom(800) {
        height: inherit;
        background-position: top 530px left;
      }
      @include custom(500) {
        background-position: top 590px left;
      }

      .flex-blocks-top {
        display: flex;
        justify-content: center;
        gap: 100px;

        @include custom(1024) {
          justify-content: space-around;
          gap: inherit;
        }

        @include custom(800) {
          flex-direction: column;
          align-items: center;
          gap: 30px;
        }

        .block:nth-child(2) {
          margin-top: -100px;

          @include custom(1024) {
            margin-top: 0;
          }
        }
      }
      .flex-blocks-bottom {
        margin-top: 150px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 100px;

        @include custom(1024) {
          margin-top: 370px;
          justify-content: space-around;
          gap: inherit;
        }

        @include custom(800) {
          flex-direction: column;
          align-items: center;
          gap: 30px;
        }

        .block:nth-child(2) {
          margin-bottom: -100px;

          @include custom(1024) {
            margin-bottom: 0;
          }
        }
      }

      .block {
        width: 180px;
        font-weight: 500;
        @include font-size(17px);
        line-height: 22px;

        @include custom(800) {
          width: inherit;
          text-align: center;
        }

        img {
          margin: 0 0 20px 40px;
          display: block;

          @include custom(800) {
            display: inline-block;
            margin: 20px 0;
          }
        }
      }
    }

    .button-coach {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 50px;

      @include custom(800) {
        margin-top: 70px;
      }
    }
  }

  .products-brushs {
    margin: 80px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;

    .product-brush {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        img {
          border-radius: 50px;
        }
      }

      img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        @include transition(all ease-in-out 0.2s);
      }

      p:first-of-type {
        margin-top: 10px;
        @include font-size(19px);
        font-weight: 500;
      }
      p:last-of-type {
        margin-top: 5px;
        @include font-size(15px);
        font-weight: 500;
        color: $color-secondary;
      }
    }
  }

  .constat {
    margin: 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;

    @include custom(1024) {
      flex-wrap: wrap;
      gap: 50px;
    }

    .titles {
      max-width: 500px;
      @include font-size(38px);

      p:first-of-type {
        font-weight: 300;
        margin-bottom: 50px;
      }
      p:last-of-type {
        font-weight: 600;
        line-height: 45px;
      }
      .line {
        margin: 30px 30px 0 0;
        background-color: $color-highlight-2;
        width: 150px;
        height: 3px;
        border-radius: 1.5px;
        float: right;
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }
}

.wrapper-choose-your-brush {
  padding: 50px 70px;

  @include custom(600) {
    padding: 50px 20px;
  }

  h1 {
    @include font-size(38px);
    font-weight: 600;
    display: inline-block;
    position: relative;

    > div {
      position: absolute;
      right: -70px;
      bottom: -15px;
      background-color: $color-highlight-2;
      width: 150px;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  .models {
    margin: 100px 0;

    @include custom(1024) {
      margin: 0;
    }

    > div {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 70px;

      @include custom(1024) {
        flex-direction: column;
        margin-top: 150px;
        gap: 40px;

        &:first-of-type {
          margin-top: 50px;
        }
      }

      .image-kid {
        background-image: url("/assets/images/image-model-kid.jpg");
        height: 330px;
        background-position: bottom right -70px;
      }

      .image-basic {
        background-image: url("/assets/images/image-model-basic.jpg");
       height: 380px;
        background-position: center;
      }

      .image-mid {
        background-image: url("/assets/images/image-model-mid.jpg");
        height: 500px;
        background-position: top left;
      }

      .image-premium {
        background-image: url("/assets/images/image-model-premium.jpg");
        height: 550px;
        background-position: center;
      }

      .image-kid,
      .image-basic,
      .image-mid,
      .image-premium  {
        width: 230px;
        background-size: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        flex-shrink: 0;

        @include custom(1024) {
          width: 100%;
          height: 350px;
          background-position: center;
        }
      }

      &:hover {
        .image-kid,
        .image-basic,
        .image-mid,
        .image-premium  {

        }
      }

      .desc {
        flex-grow: 1;
        flex-shrink: 1;

        h2 {
          @include font-size(38px);
          font-weight: 300;
        }
        p {
          max-width: 470px;
          margin-top: 30px;
          font-weight: 400;
          line-height: 23px;

          @include custom(1024) {
            max-width: inherit;
          }

          strong {
            font-weight: 600;
          }
        }
        .p-list {
          margin-top: 10px;
          @include font-size(14px);
          line-height: 18px;
        }
        .labels {
          margin-top: 50px;
          display: flex;
          justify-content: left;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.wrapper-our-values {
  padding: 50px 70px;

  @include custom(600) {
    padding: 20px 20px;
  }

  h1 {
    @include font-size(38px);
    font-weight: 600;
    display: inline-block;
    position: relative;

    > div {
      position: absolute;
      right: -70px;
      bottom: -15px;
      background-color: $color-highlight-2;
      width: 150px;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  h2 {
    @include font-size(38px);
    font-weight: 300;
    margin: 70px 100px 40px;

    @include custom(1200){
      margin: 70px 20px 40px;
    }
  }

  p {
    font-weight: 400;
    line-height: 22px;
  }

  .introduction {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 0 100px;

    @include custom(1200){
      margin: 0 20px;
    }

    @include custom(900){
      flex-direction: column;
    }

    .prez-img {
      @include custom(900){
        width: 100%;
        height: auto;
      }
    }
  }
  .col2 {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @include custom(900){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      max-width: 500px;
      margin-bottom: 30px;

      @include custom(900){
        max-width: inherit;
      }
    }

    img {
      border-radius: 20px;
    }
  }
  .introduction .col2:first-of-type {
    p {
      max-width: initial;
    }
  }

  .presentation-tv {
    @include font-size(13px);
    color: $color-secondary;
    text-align: center;
    line-height: 16px;

    p {
      line-height: inherit;
      max-width: 160px;
      margin-bottom: 0;
    }
    p:first-of-type {
      color: $color-base;
      margin-top: 10px;
    }
  }

  .history {
    position: relative;
    margin: 30px 0 150px;
    padding: 15px 100px 0;
    background-image: url("../public/assets/svg/wave.svg");
    background-size: cover;
    width: auto;
    max-width: 1366px;

    @include custom(1200){
      padding: 15px 50px 0;
      margin: 30px -70px 150px;
    }

    @include custom(900) {
      padding: 15px 30px 0;
      margin: 30px -20px 150px;
    }

    .history-img {
      margin-top: -100px;

      @include custom(900){
        margin-top: 0;
      }
    }
  }

  .image-revolution img {
    @include custom(1366){
      width: 100%;
      height: auto;
    }
  }

  .icon-logo {
    margin: 70px 0 200px 120px;

    @include custom(1200){
      width: 100%;
      max-width: 426px;
      margin-left: 0;
    }
    @include custom(900){
      margin: 50px 0 100px;
    }
  }

  .icon-roller {
    position: absolute;
    bottom: -60px;
    transform: translateX(-60px);
  }

  .dates {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 70px;

    @include custom(900){
      flex-direction: column;
    }

    > div {
      text-align: center;

      p:first-of-type {
        padding: 15px 35px;
        background-color: #BDF1F8;
        @include font-size(38px);
        font-weight: 500;
        display: inline-block;
        border-radius: 20px;
      }
      p:last-of-type {
        margin-top: 30px;
        max-width: 300px;
        height: 150px;
        font-style: italic;

        @include custom(900){
          height: 100px;
        }
      }
    }
  }

  .nothing {
    text-align: center;
    @include font-size(22px);
  }

  .today {
    text-align: center;
    padding: 50px;
    color: #00FFDC;
    background-color: #ffffff;
    @include font-size(80px);
    font-weight: 500;
    display: inline-block;
    border-radius: 50px;
  }

  .revolution {
    text-align: center;
    @include font-size(28px);
    line-height: 35px;
    max-width: 800px;
    margin: 0 auto;
  }

  .experience {
    margin: 70px 0 0;

    > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      @include custom(900){
        flex-direction: column;
      }

      > div {
        max-width: 700px;
        margin: 0 20px;
      }
    }
  }

}

.footer-newsletter {
  width: 100%;
  background-color: #ECF8FE;
  padding: 50px 20px;
  margin:  0;

  .newsletter-title {
    @include font-size(24px);
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }

  .newsletter-description {
    @include font-size(17px);
    margin-bottom: 30px;
    text-align: center;
  }

  .newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;

    @include custom(600) {
      flex-direction: column;
      width: 100%;
    }

    input[type="email"] {
      flex: 1;
      min-width: 300px;
      height: 50px !important;
      padding: 0 25px;
      border: 1px solid #E5E5E5;
      border-radius: 25px;
      font-size: 15px;
      background-color: white;

      @include custom(600) {
        padding: 11px 20px;
        width: 100%;
        min-width: auto;
      }

      &:focus {
        outline: none;
        border-color: $color-highlight-2;
      }
    }

    button {
      background-color: $color-secondary;;
      color: white;
      border: none;
      height: 50px;
      padding: 0 35px;
      border-radius: 25px;
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase;
      @include font-size(15px);
      @include transition(background-color ease-in-out 0.2s);

      @include custom(600) {
        width: 100%;
      }

      &:hover {
        background-color: $color-base;
      }
    }
  }
}