@import "../../styles/style-config.scss";
@import "../../styles/style-functions.scss";

/* Footer section */
footer {
    max-width: 1366px;
    margin: 60px auto 0 auto;
  
    .footer-infos {
      width: 100%;
      padding: 20px 0;
      background-color: $color-secondary;
      overflow: hidden;
  
      @include mf-md {
        padding: 60px 0;
      }
  
      .infos {
        max-width: 1366px;
        padding: 0 30px;
        display: flex;
        flex-wrap: wrap;
  
        @include md {
          display: block;
        }
  
        .info {
          flex-grow: 1;
          text-align: center;
          vertical-align: top;
          margin: 0 auto;
  
          &:last-child {
            margin-bottom: 40px;
  
            @include mf-md {
              margin-bottom: 0;
            }
          }
  
          @include mf-md {
            width: 33%;
            margin: 0;
          }
          .icon {
            margin: 40px 0 20px 0;
  
            @include mf-md {
              margin-top: 0;
            }
          }
          p {
            line-height: 21px;
            font-weight: 500;
          }
        }
      }
    }
  
    .footer-links {
      position: relative;
      @include box-sizing(border-box);
      width: 100%;
      padding: 30px 30px 0 30px;
      overflow: hidden;
  
      .socials-medias {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
  
        @include mf-md {
          position: absolute;
          top: 55%;
          -ms-transform: translateY(-55%);
          transform: translateY(-55%);
        }
  
        svg {
          margin-right: 10px;
          cursor: pointer;
  
          .a {
            fill: $color-base;
            @include transition(all ease-in-out 0.2s);
          }
          &:hover {
            .a {
              fill: $color-highlight;
            }
          }
        }
      }
      .links {
        margin-bottom: 30px;
  
        @include mf-md {
          margin-bottom: 0;
        }
  
        ul {
          list-style: none;
  
          li {
            line-height: 30px;
            cursor: pointer;
            text-align: center;
  
            @include mf-md {
              text-align: right;
            }
  
            &:hover {
              color: $color-highlight;
            }
          }
        }
      }
    }

    .footer-language-currency {
      margin: 30px;
      padding: 40px 0 20px 0;
      border-top: solid 2px $color-secondary;
      text-align: center;
      @include font-size(13px);

      p {
        span {
          padding: 5px 8px;
          cursor: pointer;
          border-radius: 5px;
          line-height: 30px;

          &:hover {
            text-decoration: underline;
          }
        }

        span.active {
          color: $color-highlight;
          font-weight: bold;
          background-color: $color-highlight-light;

          &:hover {
            text-decoration: none;
            cursor: initial;
          }
        }
      }

      .separator {
        margin: 0 0 0 15px;
        padding: 2px 15px 2px 0;
        border-left: solid 1px $color-base;
        @include opacity(0.5);
        border-radius: 0;
      }
    }
  
    .footer-copy {
      margin: 30px;
      padding: 15px 0 30px 0;
      border-top: solid 2px $color-secondary;
      text-align: center;
  
      @include mf-md {
        text-align: inherit;
      }
  
      p {
        font-size: small;
        margin: 15px 0 0 0;
  
        @include mf-md {
          float: right;

          a {
            color: $color-highlight;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      img {
        width: 100%;
        max-width: $logotype-footer-max-size;
      }
    }
  }
@import "../../styles/client-ROL-overwrite.scss";