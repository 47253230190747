@import "../../styles/style-config.scss";
@import "../../styles/style-functions.scss";

/* Header section */
header {
  overflow: hidden;

  .headerband {
    display: none;
    z-index: 10;
    width: 100%;
    background-color: $header-top-header-background;
    color: $header-top-header-color-text;
    padding: 20px 45px;
    text-align: center;
    @include font-size(16px);
  }
  .headerband-open {
    display: block;
  }

  nav {
    //position: fixed;
    z-index: 5;
    padding: 25px 30px 0 30px;
    margin-bottom: 120px;
    width: 100%;
    height: 90px;
    background-color: $header-nav-background-color;
    @include box-sizing(border-box);

    @include mf-sm {
      padding: 50px 50px 0 50px;
      margin-bottom: 20px;
      height: 135px;
    }


    .nav-logotype {
      width: 100%;
      text-align: center;
      cursor: pointer;
      margin-bottom: 30px;
      @include transition(all ease-in-out 0.2s);

      @include mf-sm {
        float: left;
        width: 25%;
        margin-bottom: 0;
      }

      img {
        width: 100%;
        max-width: $logotype-max-size;
        min-width: $logotype-min-size;
      }
    }

    /*.nav-search {
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mf-xl {
        width: 25%;
      }

      .search-input-desk {
        position: relative;
        width: 100%;
      }

      .search-input-mobile {
        position: absolute;
        top: 70px;
        left: 10px;
        width: 95%;
      }

      .search-button-desk {
        position: absolute;
        top: 10px;
        right: -22px;
        width: 10px;
      }

      .search-button-mobile {
        position: absolute;
        top: 11px;
        right: -10px;
        width: 80px;
        overflow: visible;
      }

      svg {
        margin-right: 20px;
        width: 45px;
        height: 45px;

        .a {
          fill: $color-highlight;
        }

        @include custom(540) {
          margin-right: 0;
        }
      }

      button {
        background-color: transparent;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        padding-right: 40px;

        @include custom(540) {
          border-width: 3px;
          border-color: $input-color-border-mobile;
          background-color: $input-color-background-mobile;
          @include box-sizing(border-box);
        }

        &:focus {
          @include custom(540) {
            background-color: $input-color-background-onfocus-mobile;
          }
        }
      }
    }*/

    .nav-menu {
      display: none;
      margin: 0 auto;

      @include mf-xl {
        display: table;
      }

      > ul {
        > li {
          display: inline-block;
          padding: 0 15px;
          margin-top: -10px;
          line-height: 60px;
          cursor: pointer;
          @include transition(all ease-in-out 0.2s);

          &:hover {
            border-bottom: solid 1px $color-base;
          }

          &:hover .submenu {
            display: flex;
          }

          @include custom(1300) {
            padding: 0 8px;
          }

          img {
            margin: 0 0 0 5px;
          }
        }

        > li.active {
          color: $color-highlight;
          border-bottom: solid 1px $color-highlight;
        }
      }

      .submenu {
        display: none;
        position: absolute;
        background: $header-nav-submenu-background-color;
        margin-top: 0;
        padding: 50px;
        color: $color-base;
        left: 0;
        right: 0;
        justify-content: space-evenly;
        z-index: 10;
        @include transition(all ease-in-out 0.2s);

        li {
          line-height: 30px;
          padding: 0 15px;
          cursor: pointer;

          &:hover {
            color: $color-highlight;
            border-radius: 20px;
            background-color: $submenu-color-background-onhover;
          }

          &.active {
            border-radius: 20px;
            background-color: $color-highlight;
            color: $submenu-active-color-text;
            padding: 5px 15px;
          }
        }

        li.submenu-title {
          line-height: 40px;
          font-weight: 700;

          &:hover {
            color: inherit;
          }
        }

        li.submenu-title:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }


  .nav-access {
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;

    @include sm {
      & :nth-child(1) { order: 2; }
      & :nth-child(2) { order: 1; }
      & :nth-child(3) { order: 3; width: 410px; }
    }

    @include mf-sm {
      justify-content: flex-end;
      flex-direction: inherit;
      width: initial;
    }

    @include mf-xl {
      width: 25%;
    }

    .config-icon {
      display: flex;
      padding: 3px 0 13px 0;
      cursor: pointer;
    }

    /*.config {
      &:hover .config-submenu {
        display: flex;
      }

      img {
        float: right;
      }

      .config-icon {
        margin: 0 10px 0 0;
      }

      .config-submenu {
        display: none;
        position: absolute;
        background: $header-nav-submenu-background-color;
        margin-top: 0;
        padding: 50px;
        left: 0;
        right: 0;
        justify-content: space-evenly;
        @include transition(all ease-in-out 0.2s);

        li {
          line-height: 30px;
          padding: 5px 15px;
          cursor: pointer;

          &:hover {
            color: $color-highlight;
            border-radius: 20px;
            background-color: $submenu-color-background-onhover;
          }

          &.active {
            border-radius: 20px;
            background-color: $color-highlight;
            color: $submenu-active-color-text;
          }
        }

        li.submenu-title {
          line-height: 40px;
          font-weight: 700;

          &:hover {
            color: inherit;
          }
        }

        li.submenu-title:not(:first-child) {
          margin-top: 20px;
        }
      }
    }*/

    .config-icon {
      flex-direction: initial;

      .ic-account-a { fill: $color-base; }
      .ic-account-b { fill: $color-base; fill-rule:evenodd; }
      .ic-account-c { fill: $color-highlight; }
      .ic-account-d { fill: white; }

      .ic-shopping-a { fill: $color-base; fill-rule:evenodd; }

      @include sm {
        flex-direction: row-reverse;
      }

      &:hover svg {
        .ic-account-a, .ic-account-b {
          fill: $color-highlight;
        }
      }

      svg {
        cursor: pointer;
        @include transition(all ease-in-out 0.2s);

        .ic-account-a, .ic-account-b {
          @include transition(all ease-in-out 0.2s);
        }

        &:hover {
          .ic-account-a, .ic-account-b {
            fill: $color-highlight;
          }
        }
      }
    }

    svg,
    div:first-child {
      margin: 0 20px 0 0;
    }

    .svg-account,
    .svg-shopping {
      margin: 0 8px 0 0;
    }

    /*svg:last-child,
    .svg-shopping {
      margin: 0;
    }*/

    .shopping-count {
      width: 30px;
      height: 30px;
      margin: -10px 0 0 -16px;
      background-color: $color-highlight;
      color: $header-shopping-count-color-text;
      text-align: center;
      line-height: 30px;
      font-weight: bold;
      border-radius: 50%;
      cursor: pointer;
      z-index: 5;
      @include font-size(15px);
      @include transition(all ease-in-out 0.2s);
    }

    .open-menu {
      margin: -5px 0 0 20px;

      @include mf-md {
        margin: -5px 0 0 30px;
      }

      @include mf-xl {
        display: none;
      }
    }
  }

  /*.logotype {
    z-index: 4;
    margin: 150px auto 80px auto;
    text-align: center;
    cursor: pointer;
    @include transition(all ease-in-out 0.2s);

    img {
      width: 100%;
      max-width: $logotype-max-size;
    }

    img,
    p {
      padding: 0 20px;
    }
  }*/
}
