@charset "utf-8";
@import "./style-config.scss";
@import "./style-functions.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";


*, *:after, *:before {
  @include box-sizing(border-box);
  outline: none !important;
}
html, body {
  background: $body-background-color;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
  color: $color-base;
}

/* Global Components */
a, .button {
  @include transition(all ease-in-out 0.2s);
}
a {
  color: $color-base;

  &:hover {
    color: $color-highlight;
  }
}
input, textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Roboto, sans-serif;
}
input[type=text],
input[type=number],
input[type=password],
input[type=email],
textarea {
  background-color: $input-color-background;
  padding: 11px 20px;
  color: $input-color-text;
  border: solid 2px $input-color-border;
  border-radius: 30px;
  @include box-sizing(content-box);
  @include transition(border ease-in-out 0.2s, background ease-in-out 0.2s);

  &:focus {
    border: solid 2px $input-color-border-onfocus;
    background-color: $input-color-background-onfocus;
  }

  &:disabled {
    cursor:not-allowed
  }
}

::placeholder {
  color: $input-color-text-placeholder;
  font-style: italic;
  font-weight: 400;
  @include opacity(1);
}
:-ms-input-placeholder {
  color: $input-color-text-placeholder;
}
::-ms-input-placeholder {
  color: $input-color-text-placeholder;
}


/* Main content section */
main {
  max-width: 1366px;
  margin: 0 auto;

  .spacer {
    width: 100%;
    height: 150px;
  }
  .hide {
    display: none;
  }
}

